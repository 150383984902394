import {Box} from '@indoqa/style-system'
import {authenticator, Permission} from '@oegbv/ui-shared'
import {IStyle} from 'fela'
import {useFela} from 'react-fela'
import {aemRecommendationsPath} from '../../aem/aem.paths'
import {Theme} from '../../app/theme'
import {arfbrBooksPath, arfbrWebCPath} from '../../arfbr/arfbr.paths'
import {booksUsageStatisticsPath} from '../../books/books.paths'
import {indexingPath} from '../../indexing/indexing.paths'
import {ProviderTypes} from '../../indexing/store/indexing.types'
import {
  kvDocsetsPath,
  kvExportsPath,
  kvMergeTablesPath,
  kvUsageStatisticsPath,
  kvValidationPath,
} from '../../kv/kv.paths'
import {
  userManagementExport,
  userManagementUserGroupPath,
  userManagementUserPath,
} from '../../user-management/user-management.paths'
import {AdminNavLink} from './AdminNavLink'

export const AdminNavigation = () => {
  const {theme} = useFela<Theme>()
  const containerStyle: IStyle = {
    overflowY: 'auto',
    borderRight: theme.borders.border1,
  }

  const indexingAdminPermission = authenticator.hasPermission(Permission.CP_ADMIN_INDEXING)
  // const lohndatenAdminPermission = authenticator.hasPermission(Permission.CP_ADMIN_LOHNDATEN)
  const adminPermission = authenticator.hasPermission(Permission.CP_ADMIN_ALL)

  return (
    <Box<Theme> fullHeight style={containerStyle} p={2}>
      {indexingAdminPermission && (
        <Box pb={3}>
          <h3>Indexierung</h3>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.ALL)}>Alle Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.KV)}>KV Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.BOOKS)}>Books Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.WEBC)}>WEBC Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.RIS)}>RIS Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.AEM)}>AEM Updates</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={indexingPath(ProviderTypes.OWCS)}>OWCS Updates</AdminNavLink>
          </Box>
        </Box>
      )}

      {adminPermission && (
        <Box pb={3}>
          <h3>Benutzermanagement</h3>
          <Box>
            <AdminNavLink to={userManagementUserPath()}>Benutzer</AdminNavLink>
            <AdminNavLink to={userManagementUserGroupPath()}>Gruppen</AdminNavLink>
            <AdminNavLink to={userManagementExport()}>Export</AdminNavLink>
          </Box>
        </Box>
      )}

      {adminPermission && (
        <Box pb={3}>
          <h3>KV-System</h3>
          <Box>
            <AdminNavLink to={kvDocsetsPath(undefined, 'on')}>Docsets</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={kvExportsPath()}>Exports</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={kvUsageStatisticsPath()}>Nutzungsstatistik</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={kvValidationPath()}>Annotationen validieren</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={kvMergeTablesPath()}>Tabellen Zusammenführen</AdminNavLink>
          </Box>
        </Box>
      )}

      {adminPermission && (
        <Box pb={3}>
          <h3>Bücher</h3>
          <Box>
            <AdminNavLink to={booksUsageStatisticsPath()}>Nutzungsstatistik</AdminNavLink>
          </Box>
        </Box>
      )}

      {adminPermission && (
        <Box pb={3}>
          <h3>ARfBR</h3>
          <Box>
            <AdminNavLink to={arfbrBooksPath()}>Bücher</AdminNavLink>
          </Box>
          <Box>
            <AdminNavLink to={arfbrWebCPath()}>WebC-Inhalte</AdminNavLink>
          </Box>
        </Box>
      )}

      {adminPermission && (
        <Box pb={3}>
          <h3>AEM</h3>
          <Box>
            <AdminNavLink to={aemRecommendationsPath()}>Empfehlungen</AdminNavLink>
          </Box>
        </Box>
      )}
    </Box>
  )
}
