import {Box} from '@indoqa/style-system'
import * as React from 'react'

export const KvExportsPage = () => {
  return (
    <Box p={4}>
      <h3>KV-Exports</h3>
      <Box pt={2}>
        <ul>
          <li>
            <a download href={`/api/v1/kv/docset-infos-export`}>
              Docsets
            </a>
          </li>
          <li>
            <a download href={`/api/v1/kv/lohndaten-infos-export`}>
              Lohndaten - Mindestlöhne
            </a>
          </li>
          <li>
            <a download href={`/api/v1/kv/lohndaten-teiler-export`}>
              Lohndaten - Teiler
            </a>
          </li>
        </ul>
      </Box>
    </Box>
  )
}
