import {renderRebootCss} from '@indoqa/style-system'
import {authLoginPath, authLogoutPath, baseCssProps, DevLoginPage, DevLogoutPage, loadFonts} from '@oegbv/ui-shared'
import * as React from 'react'
import {ThemeProvider, useFela} from 'react-fela'
import {Redirect, Route, Switch} from 'react-router-dom'
import {AdminPage} from '../admin/pages/AdminPage'
import {aemRecommendationsPath} from '../aem/aem.paths'
import {AemRecommendationsPage} from '../aem/pages/AemRecommendationsPage'
import {arfbrBooksPath, arfbrWebCPath} from '../arfbr/arfbr.paths'
import {ARfBRBooksPage} from '../arfbr/pages/ARfBRBooksPage'
import {ARfBRWebCPage} from '../arfbr/pages/ARfBRWebCPage'
import {booksUsageStatisticsPath} from '../books/books.paths'
import {BooksUsageStatisticsPage} from '../books/pages/BooksUsageStatisticsPage'
import {indexingPath, indexingPathParams, indexingWithSubTypePath} from '../indexing/indexing.paths'
import {IndexingPage} from '../indexing/pages/IndexingPage'
import {kvDocsetsPath, kvExportsPath, kvMergeTablesPath, kvUsageStatisticsPath, kvValidationPath} from '../kv/kv.paths'
import {KvDocsetsPage} from '../kv/pages/KvDocsetsPage'
import {KvExportsPage} from '../kv/pages/KvExportsPage'
import {KvMergeTablesPage} from '../kv/pages/KvMergeTablesPage'
import {KvUsageStatisticsPage} from '../kv/pages/KvUsageStatisticsPage'
import {KVValidationPage} from '../kv/pages/KVValidationPage'
import {UserManagementExportPage} from '../user-management/pages/UserManagementExportPage'
import {UserManagementUserGroupPage} from '../user-management/pages/UserManagementUserGroupPage'
import {UserManagementUserPage} from '../user-management/pages/UserManagementUserPage'
import {
  userManagementExport,
  userManagementUserGroupPath,
  userManagementUserPath,
} from '../user-management/user-management.paths'
import theme from './theme'

const paths = {
  userManagementUser: userManagementUserPath(),
  userManagementUserGroup: userManagementUserGroupPath(),
  userManagementExport: userManagementExport(),
  indexing: indexingPath(`:${indexingPathParams.type}`),
  indexingWithSubtype: indexingWithSubTypePath(`:${indexingPathParams.type}`, `:${indexingPathParams.subType}`),
  kvMergeTables: kvMergeTablesPath(),
  kvValidation: kvValidationPath(),
  kvUsageStatistics: kvUsageStatisticsPath(),
  kvMindestlohnPath: kvExportsPath(),
  kvDocsets: kvDocsetsPath(),
  arfbrBooks: arfbrBooksPath(),
  arfbrWebc: arfbrWebCPath(),
  booksUsageStatistics: booksUsageStatisticsPath(),
  aemRecommendations: aemRecommendationsPath(),
}

const App: React.FC = () => {
  const {renderer} = useFela()
  React.useLayoutEffect(() => {
    renderRebootCss(renderer, baseCssProps(theme))
    loadFonts(renderer)
  }, [renderer])
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path={paths.indexingWithSubtype}>
          <AdminPage>
            <IndexingPage />
          </AdminPage>
        </Route>
        <Route path={paths.indexing}>
          <AdminPage>
            <IndexingPage />
          </AdminPage>
        </Route>
        <Route path={paths.userManagementUserGroup}>
          <AdminPage>
            <UserManagementUserGroupPage />
          </AdminPage>
        </Route>
        <Route path={paths.userManagementUser}>
          <AdminPage>
            <UserManagementUserPage />
          </AdminPage>
        </Route>
        <Route path={paths.userManagementExport}>
          <AdminPage>
            <UserManagementExportPage />
          </AdminPage>
        </Route>
        <Route path={paths.arfbrBooks}>
          <AdminPage>
            <ARfBRBooksPage />
          </AdminPage>
        </Route>
        <Route path={paths.arfbrWebc}>
          <AdminPage>
            <ARfBRWebCPage />
          </AdminPage>
        </Route>
        <Route path={paths.kvValidation}>
          <AdminPage>
            <KVValidationPage />
          </AdminPage>
        </Route>
        <Route path={paths.kvMergeTables}>
          <AdminPage>
            <KvMergeTablesPage />
          </AdminPage>
        </Route>
        <Route path={paths.kvUsageStatistics}>
          <AdminPage>
            <KvUsageStatisticsPage />
          </AdminPage>
        </Route>
        <Route path={paths.kvDocsets}>
          <AdminPage>
            <KvDocsetsPage />
          </AdminPage>
        </Route>{' '}
        <Route path={paths.kvMindestlohnPath}>
          <AdminPage>
            <KvExportsPage />
          </AdminPage>
        </Route>
        <Route path={paths.booksUsageStatistics}>
          <AdminPage>
            <BooksUsageStatisticsPage />
          </AdminPage>
        </Route>
        <Route path={paths.aemRecommendations}>
          <AdminPage>
            <AemRecommendationsPage />
          </AdminPage>
        </Route>
        <Route exact path={authLoginPath()} component={DevLoginPage} />
        <Route exact path={authLogoutPath()} component={DevLogoutPage} />
        <Redirect from="/" to={indexingPath('all')} />
      </Switch>
    </ThemeProvider>
  )
}

// must be a default export to enable hot-reloading!
export default App
