import {Box, Text} from '@indoqa/style-system'
import * as React from 'react'
export const UserManagementExportPage = () => {
  const [role, setRole] = React.useState('Alle')
  const link = `/api/v1/user-management/keycloak-users/export${role === 'Alle' ? '' : `?role=${role}`}`

  return (
    <Box p={4}>
      <h3>Export Benutzer</h3>
      <Box>
        <Text pr={2}>Rolle:</Text>
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="alle">Alle</option>
          <option value="arbvg">ARBVG (Buch)</option>
          <option value="ARfBR">Arbeitsrecht für Betriebsräte Plus</option>
          <option value="ARfBR-Premium">Arbeitsrecht für Betriebsräte Premium</option>
          <option value="CPAdmin">Content-Pools Admin UI</option>
          <option value="CPAdminIndexer">Content-Pools Admin UI Indexer</option>
          <option value="Buecher">eBooks</option>
          <option value="GEMS">GEMS</option>
          <option value="LohndatenApiAdmin">KV-Lohndaten-API Admin</option>
          <option value="LohndatenApiUser">KV-Lohndaten-API User</option>
          <option value="KVSystem">KV-System</option>
          <option value="loeschnigg">Löschnigg (Buch)</option>
          <option value="XMLApiUser">XML-API User</option>
        </select>
      </Box>
      <Box pt={2}>
        <a download href={link}>
          Download
        </a>
      </Box>
    </Box>
  )
}
